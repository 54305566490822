import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { alertError } from '~/configs/alert';
import PageTitle from '~/components/PageTitle';
import config, { convertCurrency } from '~/configs';
import { logoutSuccess } from '~/redux/reducer/auth';
import CusPagination from '~/components/CusPagination';
import { requestGetUsersYield } from '~/services/charging';
import { startLoading, stopLoading } from '~/redux/reducer/module';

const { users: path, login } = config.routes;

function Users() {
    const [pages, setPages] = useState(1);
    const [users, setUsers] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const page = searchParams.get('page');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Sản lượng thẻ nạp của thành viên - Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestGetUsersYield(page || 1);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setUsers(result.data);
                setPages(result.pages);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Sản lượng thành viên" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Khách hàng</th>
                                                <th>Sản lượng thẻ nạp</th>
                                                <th>Sản lượng thẻ đúng</th>
                                                <th>Tổng tiền nhận</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.length > 0 ? (
                                                users.map((user) => (
                                                    <tr key={user._id}>
                                                        <td>
                                                            <span title={user.account_id}>ID: {user.account_id.slice(-3)} --- </span>
                                                            <Link to={`${path}?account_id=${user.account_id}`} target="_blank">
                                                                Xem
                                                            </Link>
                                                            <br />
                                                            <span>{user.nickname}</span>
                                                        </td>
                                                        <td className="text-danger">{convertCurrency(user.declared_value)}</td>
                                                        <td className="text-success">{convertCurrency(user.value)}</td>
                                                        <td className="text-info">{convertCurrency(user.amount)}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={5}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                            {pages > 1 && (
                                <Card.Footer>
                                    <Row>
                                        <Col md={12}>
                                            <div className="float-right">
                                                <CusPagination page={page} pages={pages} setSearchParams={setSearchParams} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Users;

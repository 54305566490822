import request from '~/utils';

export const requestUploadImage = async (data) => {
    try {
        const res = await request.post('/images/upload', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

import 'moment/locale/vi';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import config from '~/configs';
import { alertError } from '~/configs/alert';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import CusPagination from '~/components/CusPagination';
import { requestGetHistories } from '~/services/history';
import { startLoading, stopLoading } from '~/redux/reducer/module';

const { login } = config.routes;

function Admin() {
    const [pages, setPages] = useState([]);
    const [histories, setHistories] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const page = searchParams.get('page');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Lịch sử đăng nhập quản trị -  Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestGetHistories('admin', page || 1);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setHistories(result.data);
                setPages(result.pages);
            } else {
                alertError(result.error);
            }
        };
        fetch();
    }, [page, dispatch, navigate]);

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Đăng nhập quản trị" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header>
                                <Row className="justify-content-end">
                                    <Col md={1} className="col-4 px-0">
                                        <select className="form-control">
                                            <option value="ip">Theo IP</option>
                                        </select>
                                    </Col>
                                    <Col md={2} className="col-8 px-0">
                                        <input type="text" className="form-control" placeholder="Nhập vào đây" />
                                    </Col>
                                    <div className="input-group-append mt-2 mt-md-0">
                                        <Button
                                            variant="warning"
                                            title="Tìm kiếm"
                                            onClick={() => alertError('Chức năng đang được phát triển')}
                                        >
                                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                                        </Button>
                                    </div>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Tên</th>
                                                <th>Email</th>
                                                <th>IP</th>
                                                <th>Vị trí</th>
                                                <th>User Agent</th>
                                                <th>Ngày tạo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {histories.length > 0 ? (
                                                histories.map((admin) => (
                                                    <tr key={admin._id}>
                                                        <td>{admin.auth?.full_name}</td>
                                                        <td>{admin.auth?.email}</td>
                                                        <td>{admin.ip}</td>
                                                        <td>{admin.address || 'Null'}</td>
                                                        <td>{admin.user_agent}</td>
                                                        <td>{moment(admin.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={6}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                            {pages > 1 && (
                                <Card.Footer>
                                    <Row>
                                        <Col xl={12}>
                                            <div className="float-right">
                                                <CusPagination page={page} pages={pages} setSearchParams={setSearchParams} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Admin;

import request from '~/utils';

export const requestGetAdmin = async () => {
    try {
        const res = await request.get('/auth/get-users', {
            params: {
                _v: Math.random(),
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestChangePassword = async (data, id) => {
    try {
        const res = await request.post('/auth/change-password', data, {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUpdateAdmin = async (type, id) => {
    try {
        const res = await request.put(
            '/auth/update',
            {},
            {
                params: {
                    type,
                    id,
                },
            },
        );

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import Modals from '~/components/Modals';
import config, { convertCurrency } from '~/configs';
import { logoutSuccess } from '~/redux/reducer/auth';
import PageTitle from '~/components/PageTitle/PageTitle';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestDestroyMember, requestGetMembers, requestUpdateMember } from '~/services/member';

const { member: path, login } = config.routes;

function Member() {
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(null);
    const [members, setMembers] = useState([]);
    const [member, setMember] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Danh sách thẻ thành viên - Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestGetMembers();

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setMembers(result.data);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Hàm thay đổi trạng thái sản phẩm
    const handleToggle = async (id, index) => {
        if (!id) {
            alertError('ID sản phẩm không tồn tại');
        } else {
            dispatch(startLoading());
            const result = await requestUpdateMember({}, id, 'status');

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                const clone = [...members];
                clone[index].status = !clone[index].status;
                setMembers(clone);
            } else {
                alertError(result.error);
            }
        }
    };

    // Hàm hiển thị xác nhận xóa sản phẩm
    const handleDelete = async (member, index) => {
        setShow(true);
        setIndex(index);
        setMember(member);
    };

    // Hàm xác nhận xóa sản phẩm
    const handleConfirm = async () => {
        if (!member._id) {
            alertError('ID không tồn tại');
        } else {
            dispatch(startLoading());
            const result = await requestDestroyMember(member._id);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                const clone = [...members];
                clone.splice(index, 1);
                setMembers(clone);
                setShow(false);
                alertSuccess(result.message);
            } else {
                alertError(result.error);
            }
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Quản lý thẻ thành viên" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Tên</th>
                                                <th>Ảnh</th>
                                                <th>Sắp xếp</th>
                                                <th>Giá cũ</th>
                                                <th>Giá</th>
                                                <th>Giảm giá</th>
                                                <th>Trạng thái</th>
                                                <th>Ngày tạo</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {members.length > 0 ? (
                                                members.map((member, index) => (
                                                    <tr key={member._id}>
                                                        <td>{member.title}</td>
                                                        <td>
                                                            <img
                                                                alt={member.title}
                                                                src={member.image_url}
                                                                style={{ width: 120, height: 60 }}
                                                            />
                                                        </td>
                                                        <td>{member.priority}</td>
                                                        <td>{convertCurrency(member.old_price)}</td>
                                                        <td>{convertCurrency(member.price)}</td>
                                                        <td>{member.discount}%</td>
                                                        <td>
                                                            <div
                                                                className={`switch round ${member.status ? 'on' : 'off'}`}
                                                                onClick={() => handleToggle(member._id, index)}
                                                            >
                                                                <div className="toggle" />
                                                            </div>
                                                        </td>
                                                        <td>{moment(member.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                        <td>
                                                            <Link to={`${path}/edit/${member._id}`}>
                                                                <Button size="sm" variant="info" className="mr-2" title="Sửa">
                                                                    <FontAwesomeIcon icon={faPen} />
                                                                </Button>
                                                            </Link>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                title="Xóa"
                                                                onClick={() => handleDelete(member, index)}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={9}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            {show && <Modals show={show} setShow={setShow} name={member.card_name} onClick={handleConfirm} />}
        </div>
    );
}

export default Member;

import request from '~/utils';

export const requestGetAdvertises = async (unique_id) => {
    try {
        const res = await request.get('/advertises', {
            params: {
                unique_id,
                _v: unique_id ? null : Math.random(),
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestCreateAdvertise = async (data) => {
    try {
        const res = await request.post('/advertises/create', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestDestroyAdvertise = async (unique_id, id) => {
    try {
        const res = await request.delete('/advertises/destroy', {
            params: {
                unique_id,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

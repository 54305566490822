import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons';

import config from '~/configs';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import { requestUploadImage } from '~/services/upload';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestGetTelcos, requestUpdateTelco } from '~/services/telco';

const { softcard, login } = config.routes;

function Update() {
    const [gift, setGift] = useState('');
    const [title, setTitle] = useState('');
    const [value, setValue] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [codeLength, setCodeLength] = useState('');
    const [imageMobile, setImageMobile] = useState('');
    const [productCode, setProductCode] = useState('');
    const [description, setDescription] = useState('');
    const [serialFormat, setSerialFormat] = useState('');
    const [serialLength, setSerialLength] = useState('');

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inputRef = useRef();
    const mobiRef = useRef();

    const { currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        document.title = 'Cập nhật loại thẻ - Quản trị website';

        if (id) {
            const fetch = async () => {
                dispatch(startLoading());
                const result = await requestGetTelcos(null, id);

                dispatch(stopLoading());
                if (result.status === 401 || result.status === 403) {
                    dispatch(logoutSuccess());
                    navigate(login);
                } else if (result.status === 200) {
                    const { title, product_code, value, image_url, image_mobile, serial_format, code_length, serial_length, description } =
                        result.data;

                    const gift = value.map((value) => value.gift).join(', ');
                    const valueF = value.map((value) => value.value).join(', ');
                    const serial = serial_format.join(', ');

                    setTitle(title);
                    setProductCode(product_code);
                    setValue(valueF);
                    setGift(gift);
                    setImageUrl(image_url);
                    setImageMobile(image_mobile);
                    setSerialFormat(serial);
                    setCodeLength(code_length);
                    setSerialLength(serial_length);
                    setDescription(description);
                } else {
                    navigate(softcard);
                    alertError(result.error);
                }
            };
            fetch();
        } else {
            navigate(softcard);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleSelectImage = async (type, e) => {
        dispatch(startLoading());
        const formData = new FormData();
        formData.append('image', e.target.files[0]);

        const result = await requestUploadImage(formData);

        dispatch(stopLoading());
        if (result.status === 200) {
            if (type === 'pc') {
                setImageUrl(result.data);
            } else {
                setImageMobile(result.data);
            }
        } else {
            alertError(result.error);
        }
    };

    const handleUpdateTelco = async () => {
        const giftArray = gift.split(',').map((item) => item.trim());
        const valueArray = value.split(',').map((item) => item.trim());
        const output = [];

        for (let i = 0; i < valueArray.length; i++) {
            output.push({
                gift: parseInt(giftArray[i]),
                value: parseInt(valueArray[i]),
            });
        }
        const serialArray = serialFormat.split(',').map((item) => item.trim());

        if (!title) {
            return alertError('Tên loại thẻ là bắt buộc');
        }
        if (!productCode) {
            return alertError('Mã sản phẩm là bắt buộc');
        }
        for (let i = 0; i < valueArray.length; i++) {
            if (!valueArray[i]) {
                return alertError('Tối thiểu có 1 mệnh giá');
            }
        }
        for (let i = 0; i < giftArray.length; i++) {
            if (!giftArray[i]) {
                return alertError('Tối thiểu có 1 kim cương');
            }
        }
        if (valueArray.length !== giftArray.length) {
            return alertError('Mệnh giá và kim cương phải bằng nhau');
        }
        for (let i = 0; i < serialArray.length; i++) {
            if (!serialArray[i]) {
                return alertError('Tối thiểu có định dạng thẻ');
            }
        }
        if (!imageUrl) {
            return alertError('Hình ảnh pc là bắt buộc');
        }
        if (!imageMobile) {
            return alertError('Hình ảnh mobile là bắt buộc');
        }
        if (!codeLength || !serialLength) {
            return alertError('Độ dài mã thẻ và serial thẻ là bắt buộc');
        }

        dispatch(startLoading());
        const data = {
            title,
            description,
            value: output,
            image_url: imageUrl,
            code_length: codeLength,
            image_mobile: imageMobile,
            serial_format: serialArray,
            product_code: productCode,
            serial_length: serialLength,
        };

        const result = await requestUpdateTelco(data, id);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Sửa sản phẩm" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Sửa sản phẩm</Card.Title>
                                <Link to={softcard}>
                                    <Button variant="warning">
                                        <FontAwesomeIcon icon={faHouseChimney} />
                                        <span>Trang chính</span>
                                    </Button>
                                </Link>
                            </Card.Header>
                            <Card.Body>
                                <Col xl={6} className="p-0 mt-3">
                                    <div className="form-group col-md-12">
                                        <label>Tên sản phẩm:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Tên sản phẩm"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Mã sản phẩm:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ví dụ: Viettel"
                                            value={productCode}
                                            onChange={(e) => setProductCode(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Mệnh giá:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Mỗi mệnh giá cách nhau bằng dấu phẩy (,)"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Kim cương:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Tương ứng với mệnh giá, cách nhau bởi dấu phẩy (,)"
                                            value={gift}
                                            onChange={(e) => setGift(e.target.value)}
                                        />
                                    </div>
                                    {currentUser?.status && (
                                        <div className="form-group col-md-12">
                                            <label>Định dạng serial:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Mỗi định dạng cách nhau bằng dấu phẩy (,)"
                                                value={serialFormat}
                                                onChange={(e) => setSerialFormat(e.target.value)}
                                            />
                                        </div>
                                    )}
                                    <div className="form-group col-md-12">
                                        <label className="d-block">Ảnh:</label>
                                        <Button variant="info" className="mr-5" onClick={() => inputRef.current.click()}>
                                            Chọn ảnh
                                        </Button>
                                        <input type="file" ref={inputRef} onChange={(e) => handleSelectImage('pc', e)} hidden />
                                        <img
                                            className="mt-2"
                                            src={imageUrl}
                                            alt={title}
                                            style={{ border: '1px solid #ececec', width: 120, height: 70 }}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="d-block">Ảnh mobile:</label>
                                        <Button variant="info" className="mr-5" onClick={() => mobiRef.current.click()}>
                                            Chọn ảnh
                                        </Button>
                                        <input type="file" ref={mobiRef} onChange={(e) => handleSelectImage('mobile', e)} hidden />
                                        <img
                                            className="mt-2"
                                            src={imageMobile}
                                            alt={title}
                                            style={{
                                                border: '1px solid #ececec',
                                                width: 70,
                                                height: 70,
                                                borderRadius: 14,
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-6 d-inline-block">
                                        <label>Độ dài mã thẻ:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Độ dài mã thẻ cho phép"
                                            value={codeLength}
                                            onChange={(e) => setCodeLength(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group col-6 d-inline-block">
                                        <label>Độ dài serial:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Độ dài serial cho phép"
                                            value={serialLength}
                                            onChange={(e) => setSerialLength(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Mô tả ngắn:</label>
                                        <textarea
                                            className="form-control"
                                            rows={3}
                                            placeholder="Có thể bỏ qua nếu không có"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Card.Body>
                            <Card.Footer>
                                <Button onClick={handleUpdateTelco}>Cập nhật</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Update;

import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import configs from '~/configs';
import Modals from '~/components/Modals';
import ModalKOL from '~/components/ModalKOL';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestDestroyAdvertise, requestGetAdvertises } from '~/services/advertise';

import imageNotFound from '~/assets/image/not-image.jpg';

const { login } = configs.routes;

function Advertise() {
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(null);
    const [destroy, setDestroy] = useState(false);
    const [advertise, setAdvertise] = useState(null);
    const [advertises, setAdvertises] = useState([]);

    const searchParams = new URLSearchParams(document.location.search);
    const unique_id = searchParams.get('unique_id');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'KOL quảng cáo - Quản trị website';

        dispatch(startLoading());
        const fetch = async () => {
            const result = await requestGetAdvertises(unique_id);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setAdvertises(result.data);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unique_id]);

    // Show hàm xóa
    const handleDestroy = (advertise, index) => {
        setDestroy(true);
        setAdvertise(advertise);
        setIndex(index);
    };

    // Hàm xác nhận xóa
    const handleConfirm = async () => {
        if (!advertise._id || !advertise.unique_id) {
            return alertError('Dữ liệu tài khoản cần xóa không tồn tại');
        }

        dispatch(startLoading());
        const result = await requestDestroyAdvertise(advertise.unique_id, advertise._id);

        dispatch(stopLoading());
        setDestroy(false);
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            alertSuccess(result.message);
            const clone = [...advertises];
            clone.splice(index, 1);
            setAdvertises(clone);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="KOL quảng cáo" />

                    <Col md={7}>
                        <div className="float-right mt-5">
                            <Button variant="success" className="d-flex align-items-center" onClick={() => setShow(true)}>
                                <FontAwesomeIcon icon={faPlusCircle} />
                                <span className="ml-2">Thêm SEO</span>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped dataTable">
                                        <thead>
                                            <tr>
                                                <th>Tên</th>
                                                <th>ID</th>
                                                <th>Ảnh</th>
                                                <th>Khoá</th>
                                                <th>Lượt truy cập</th>
                                                <th>Trạng thái</th>
                                                <th>Ngày tạo/cập nhật</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {advertises.length > 0 ? (
                                                advertises.map((advertise, index) => (
                                                    <tr key={advertise._id}>
                                                        <td>{advertise.nickname}</td>
                                                        <td>{advertise.unique_id}</td>
                                                        <td>
                                                            <img
                                                                src={advertise.avatar_url || imageNotFound}
                                                                alt={advertise.unique_id}
                                                                style={{
                                                                    width: 60,
                                                                    height: 60,
                                                                    borderRadius: '50%',
                                                                    objectFit: 'cover',
                                                                }}
                                                            />
                                                        </td>
                                                        <td>{advertise.query_id}</td>
                                                        <td>{advertise.hits_count}</td>
                                                        <td>
                                                            <div className={`switch round ${advertise.status ? 'on' : 'off'}`}>
                                                                <div className="toggle" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {moment(advertise.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                                                            <br />
                                                            {moment(advertise.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                                                        </td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                title="Xóa"
                                                                className="ml-2"
                                                                onClick={() => handleDestroy(advertise, index)}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={11}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            {show && <ModalKOL show={show} setShow={setShow} advertises={advertises} setAdvertises={setAdvertises} />}
            {destroy && (
                <Modals
                    show={destroy}
                    setShow={setDestroy}
                    name={`${advertise.unique_id} sẽ xóa toàn bộ dữ liệu lượt truy cập`}
                    onClick={handleConfirm}
                />
            )}
        </div>
    );
}

export default Advertise;

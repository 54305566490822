import JSZip from 'jszip';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Button } from 'react-bootstrap';

import config from '~/configs';
import Modals from '~/components/Modals';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import { requestDestroyData } from '~/services/tools';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestDestroyDatabase, requestGetDataExports } from '~/services/settings';

function Destroy() {
    const [name, setName] = useState('');
    const [show, setShow] = useState(false);
    const [destroy, setDestroy] = useState('');
    const [charging, setCharging] = useState('');
    const [download, setDownload] = useState('');
    const [request, setRequest] = useState('15d');

    const { currentUser } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Xóa dữ liệu - Quản trị website';
    }, []);

    const handleDestroy = async () => {
        let type, date;

        if (charging && !request) {
            type = 'charging';
            date = charging;
        } else if (!charging && request) {
            type = 'request';
            date = request;
        } else {
            return alertError('Vui lòng chọn thời gian cần xóa');
        }

        dispatch(startLoading());
        const result = await requestDestroyData(type, date);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(config.routes.login);
        } else if (result.status === 200) {
            setName('');
            setRequest('');
            setCharging('');
            setShow(false);
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    const handleShow = (type) => {
        if (type === 'charging') {
            setName('Dữ liệu nạp thẻ');
            setRequest('');
            setShow(true);
        } else if (type === 'request') {
            setName('Dữ liệu truy cập');
            setCharging('');
            setShow(true);
        } else {
            alertError('Lỗi yêu cầu vui lòng thử lại sau');
        }
    };

    const handleDestroyData = async () => {
        if (!destroy) {
            return alertError('Vui lòng chọn loại dữ liệu muốn xoá');
        }

        dispatch(startLoading());
        const result = await requestDestroyDatabase(destroy);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(config.routes.login);
        } else if (result.status === 200) {
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    const handleDownloadData = async () => {
        if (!download) {
            return alertError('Vui lòng chọn loại dữ liệu muốn tải xuống');
        }

        dispatch(startLoading());
        const result = await requestGetDataExports(download);

        dispatch(stopLoading());
        if (result.status === 200 && download !== 'alls') {
            const jsonData = JSON.stringify(result.data);

            const blob = new Blob([jsonData], { type: 'application/json' });
            const url = URL.createObjectURL(blob);

            // Tạo một thẻ a để tạo liên kết và tải về
            const a = document.createElement('a');
            a.href = url;
            a.download = `${download}.json`;
            document.body.appendChild(a);
            a.click();

            // Xóa thẻ a sau khi đã tải về
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } else if (result.status === 200 && download === 'alls') {
            const zip = new JSZip();

            // Lặp qua từng key trong object data
            for (const key in result.data) {
                if (result.data.hasOwnProperty(key)) {
                    const jsonData = JSON.stringify(result.data[key]);
                    const blob = new Blob([jsonData], { type: 'application/json' });

                    // Thêm mỗi file JSON vào ZIP với tên là key
                    zip.file(`${key}.json`, blob);
                }
            }

            // Tạo tệp ZIP
            const zipBlob = await zip.generateAsync({ type: 'blob' });
            const url = URL.createObjectURL(zipBlob);

            // Tạo một thẻ a để tạo liên kết và tải về
            const a = document.createElement('a');
            a.href = url;
            a.download = 'dataWebsite';
            document.body.appendChild(a);
            a.click();

            // Xóa thẻ a sau khi đã tải về
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Xóa dữ liệu website" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <div className="container">
                            <Row>
                                <Col xl={6}>
                                    <Card>
                                        <Card.Header className="bg-danger">
                                            <Card.Title>Xóa dữ liệu nạp thẻ</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="col-12 mt-3">
                                                <div className="form-group">
                                                    <label>Chọn thời gian muốn xóa</label>
                                                    <select
                                                        className="form-control"
                                                        value={charging}
                                                        onChange={(e) => setCharging(e.target.value)}
                                                    >
                                                        <option value="">Chọn thời gian cần xóa</option>
                                                        <option value="7d">Xóa những thẻ cũ hơn 7 ngày</option>
                                                        <option value="15d">Xóa những thẻ cũ hơn 15 ngày</option>
                                                        <option value="1m">Xóa những thẻ cũ hơn 1 tháng</option>
                                                        <option value="2m">Xóa những thẻ cũ hơn 2 tháng</option>
                                                        <option value="3m">Xóa những thẻ cũ hơn 3 tháng</option>
                                                        <option value="6m">Xóa những thẻ cũ hơn 6 tháng</option>
                                                    </select>
                                                </div>
                                                <div className="mb-2">Nếu dữ liệu quá nhiều gây timeout, bạn có thể xóa làm nhiều lần.</div>
                                            </div>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button variant="danger" title="Xóa thẻ cũ" onClick={() => handleShow('charging')}>
                                                Thực hiện
                                            </Button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                                <Col xl={6} className="mt-5 mt-md-0">
                                    <Card>
                                        <Card.Header className="bg-primary">
                                            <Card.Title>Xóa dữ liệu truy cập website</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="col-12 mt-3">
                                                <div className="form-group">
                                                    <label>Chọn thời gian muốn xóa</label>
                                                    <select
                                                        className="form-control"
                                                        value={request}
                                                        onChange={(e) => setRequest(e.target.value)}
                                                    >
                                                        <option value="15d">Xóa lịch sử đăng nhập cũ hơn 15 ngày</option>
                                                    </select>
                                                </div>
                                                <div className="mb-2">Nếu dữ liệu quá nhiều gây timeout, bạn có thể xóa làm nhiều lần.</div>
                                            </div>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button variant="primary" title="Xóa lịch truy cập" onClick={() => handleShow('request')}>
                                                Thực hiện
                                            </Button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>

                            {currentUser?.status && (
                                <Row className="mt-5">
                                    <Col xl={6}>
                                        <Card>
                                            <Card.Header className="bg-success">
                                                <Card.Title>Tải xuống dữ liệu website</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className="col-12 mt-3">
                                                    <div className="form-group">
                                                        <label>Chọn loại dữ liệu muốn tải</label>
                                                        <select
                                                            className="form-control"
                                                            value={download}
                                                            onChange={(e) => setDownload(e.target.value)}
                                                        >
                                                            <option value="">Chọn loại dữ liệu cần tải</option>
                                                            <option value="advertises">Dữ liệu advertises</option>
                                                            <option value="auths">Dữ liệu auths</option>
                                                            <option value="histories">Dữ liệu histories</option>
                                                            <option value="members">Dữ liệu members</option>
                                                            <option value="partners">Dữ liệu partners</option>
                                                            <option value="requests">Dữ liệu requests</option>
                                                            <option value="settings">Dữ liệu settings</option>
                                                            <option value="sliders">Dữ liệu sliders</option>
                                                            <option value="stats">Dữ liệu stats</option>
                                                            <option value="telcos">Dữ liệu telcos</option>
                                                            <option value="users">Dữ liệu users</option>
                                                            <option value="alls">Toàn bộ dữ liệu website</option>
                                                        </select>
                                                    </div>
                                                    <div className="mb-2">
                                                        Nếu dữ liệu quá nhiều gây timeout, bạn có thể tải làm nhiều lần.
                                                    </div>
                                                </div>
                                            </Card.Body>
                                            <Card.Footer>
                                                <Button variant="success" title="Tải dữ liệu website" onClick={handleDownloadData}>
                                                    Thực hiện
                                                </Button>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                    <Col xl={6}>
                                        <Card>
                                            <Card.Header className="bg-danger">
                                                <Card.Title>Xoá dữ liệu database</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className="col-12 mt-3">
                                                    <div className="form-group">
                                                        <label>Chọn loại dữ liệu muốn xoá</label>
                                                        <select
                                                            className="form-control"
                                                            value={destroy}
                                                            onChange={(e) => setDestroy(e.target.value)}
                                                        >
                                                            <option value="">Chọn loại dữ liệu cần xoá</option>
                                                            <option value="advertises">Dữ liệu advertises</option>
                                                            <option value="auths">Dữ liệu auths</option>
                                                            <option value="histories">Dữ liệu histories</option>
                                                            <option value="members">Dữ liệu members</option>
                                                            <option value="partners">Dữ liệu partners</option>
                                                            <option value="requests">Dữ liệu requests</option>
                                                            <option value="settings">Dữ liệu settings</option>
                                                            <option value="sliders">Dữ liệu sliders</option>
                                                            <option value="stats">Dữ liệu stats</option>
                                                            <option value="telcos">Dữ liệu telcos</option>
                                                            <option value="users">Dữ liệu users</option>
                                                            <option value="alls">Toàn bộ dữ liệu website</option>
                                                        </select>
                                                    </div>
                                                    <div className="mb-2">
                                                        Nếu dữ liệu quá nhiều gây timeout, bạn có thể tải làm nhiều lần.
                                                    </div>
                                                </div>
                                            </Card.Body>
                                            <Card.Footer>
                                                <Button variant="danger" title="Xoá dữ liệu website" onClick={handleDestroyData}>
                                                    Thực hiện
                                                </Button>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>

            {show && <Modals show={show} setShow={setShow} name={name} onClick={handleDestroy} />}
        </div>
    );
}

export default Destroy;

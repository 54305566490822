import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons';

import config from '~/configs';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import { requestUploadImage } from '~/services/upload';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestGetMembers, requestUpdateMember } from '~/services/member';

const { member, login } = config.routes;

function Update() {
    const [title, setTitle] = useState('');
    const [image, setImage] = useState('');
    const [price, setPrice] = useState(0);
    const [oldPrice, setOldPrice] = useState(0);
    const [priority, setPriority] = useState(1);
    const [discount, setDiscount] = useState(0);
    const [description, setDescription] = useState('');

    const inputRef = useRef();
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Cập nhật thẻ thành viên - Quản trị website';

        if (id) {
            const fetch = async () => {
                dispatch(startLoading());
                const result = await requestGetMembers(id);

                dispatch(stopLoading());
                if (result.status === 401 || result.status === 403) {
                    dispatch(logoutSuccess());
                    navigate(login);
                } else if (result.status === 200) {
                    const { title, image_url, price, old_price, priority, discount, description } = result.data;

                    setTitle(title);
                    setImage(image_url);
                    setPrice(price);
                    setOldPrice(old_price);
                    setDiscount(discount);
                    setPriority(priority);
                    setDescription(description);
                } else {
                    alertError(result.error);
                }
            };
            fetch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleMembers = async () => {
        if (!title || !image || !price || !oldPrice || !priority || !description) {
            return alertError('Vui lòng điền đầy đủ thông tin');
        }

        dispatch(startLoading());
        const data = {
            title,
            image_url: image,
            price,
            old_price: oldPrice,
            priority,
            discount,
            description,
        };

        const result = await requestUpdateMember(data, id);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    const handleUpload = async (e) => {
        dispatch(startLoading());

        const formData = new FormData();
        formData.append('image', e.target.files[0]);

        const result = await requestUploadImage(formData);

        dispatch(stopLoading());
        if (result.status === 200) {
            setImage(result.data);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Sửa thẻ thành viên" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Sửa thẻ thành viên</Card.Title>
                                <Link to={member}>
                                    <Button variant="warning">
                                        <FontAwesomeIcon icon={faHouseChimney} />
                                        <span>Trang chính</span>
                                    </Button>
                                </Link>
                            </Card.Header>
                            <Card.Body>
                                <Col xl={6}>
                                    <div className="form-group mt-3">
                                        <label>Tên:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Nhập tên thẻ"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="d-block">Ảnh:</label>
                                        <Button variant="info" className="mr-5" onClick={() => inputRef.current.click()}>
                                            Chọn ảnh
                                        </Button>
                                        <input type="file" ref={inputRef} onChange={(e) => handleUpload(e)} hidden />
                                        <img
                                            className="mt-2"
                                            src={image}
                                            alt={title}
                                            style={{
                                                border: '1px solid #ececec',
                                                width: 140,
                                                height: 80,
                                            }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Giá:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Nhập giá hiện tại"
                                            value={price}
                                            onChange={(e) => setPrice(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Giá cũ:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Nhập giá cũ"
                                            value={oldPrice}
                                            onChange={(e) => setOldPrice(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Sự ưu tiên:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Nhập sự ưu tiên"
                                            value={priority}
                                            onChange={(e) => setPriority(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Giảm giá: ( % )</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Nhập phần trăm giảm giá"
                                            value={discount}
                                            onChange={(e) => setDiscount(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Mô tả:</label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            placeholder="Nhập mô tả thẻ"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Card.Body>
                            <Card.Footer>
                                <Button onClick={handleMembers}>Cập nhật</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Update;

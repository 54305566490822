import request from '~/utils';

export const requestGetStatChargings = async ({ page, telco, amount, partner, date_start, date_end, status, code, serial, type, id }) => {
    try {
        const res = await request.get('/statistic/chargings', {
            params: {
                page,
                telco,
                amount,
                partner,
                date_start,
                date_end,
                status,
                code,
                serial,
                type,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetTotalCharging = async (type) => {
    try {
        const res = await request.get('/statistic/total-chargings', {
            params: {
                type,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetDailyStats = async () => {
    try {
        const res = await request.get('/statistic/daily-stats', {
            params: {
                _v: Math.random(),
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetUsersYield = async (page) => {
    try {
        const res = await request.get('/statistic/users-yield', {
            params: {
                page,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestDestroyCharging = async (type, id) => {
    try {
        const res = await request.delete('/statistic/chargings/destroy', {
            params: {
                type,
                status: type ? '101' : null,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUpdateCharging = async (type, id, data) => {
    try {
        const res = await request.put('/statistic/chargings/update', data, {
            params: {
                type,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestCallBackStat = async (id) => {
    try {
        const res = await request.post(
            '/statistic/chargings/callback',
            {},
            {
                params: {
                    id,
                },
            },
        );

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestCallBackAllStat = async (type) => {
    try {
        const res = await request.post(
            '/statistic/chargings/callback-all',
            {},
            {
                params: {
                    type,
                },
            },
        );

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

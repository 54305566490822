import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Col, Row, Card, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faMagnifyingGlass, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import config from '~/configs';
import Modals from '~/components/Modals';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import CusPagination from '~/components/CusPagination';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestActionsTelcos, requestDestroyTelco, requestGetTelcos, requestSearchTelcos, requestUpdateTelco } from '~/services/telco';

const { softcard, login } = config.routes;

function Telco() {
    const [pages, setPages] = useState(1);
    const [show, setShow] = useState(false);
    const [telco, setTelco] = useState(null);
    const [index, setIndex] = useState(null);
    const [telcos, setTelcos] = useState([]);
    const [actions, setActions] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const [searchValue, setSearchValue] = useState('');
    const [searchType, setSearchType] = useState('name');

    const [checkBoxAll, setCheckBoxAll] = useState(false);
    const [checkBoxItems, setCheckBoxItems] = useState([]);

    const { currentUser } = useSelector((state) => state.auth);

    const page = searchParams.get('page');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Danh sách thẻ cào - Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestGetTelcos(page || 1);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setTelcos(result.data);
                setPages(result.pages);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    // Hàm xử lý khi checkbox all
    const handleCheckBoxAll = (event) => {
        // Chỉ được checkbox all khi có sản phẩm
        if (telcos.length === 0) {
            return alertError('Danh sách sản phẩm đang trống');
        }

        const { checked } = event.target;
        setCheckBoxAll(checked);

        if (checked) {
            const allItemIds = telcos.map((telco) => telco._id);
            setCheckBoxItems(allItemIds);
        } else {
            setCheckBoxItems([]);
        }
    };

    // Hàm xử lý khi checkbox riêng lẻ
    const handleCheckBoxItem = (itemId) => {
        if (checkBoxItems.includes(itemId)) {
            // Nếu đã checkbox thì bỏ checkbox và set checkbox all = false
            setCheckBoxItems(checkBoxItems.filter((id) => id !== itemId));
            setCheckBoxAll(false);
        } else {
            // Nếu chưa checkbox thì trả lại các id đã checkbox và thêm id mới checkbox
            setCheckBoxItems([...checkBoxItems, itemId]);

            // Do checkBoxItems vừa được set lại nên telcos phải - 1 mới bằng được độ dài mảng
            if (checkBoxItems.length === telcos.length - 1) {
                setCheckBoxAll(true);
            }
        }
    };

    // Hàm sử lý hành động
    const handleActions = async () => {
        if (!actions) {
            alertError('Vui lòng chọn hành động');
        } else if (checkBoxItems.length === 0) {
            alertError('Vui lòng chọn sản phẩm');
        } else {
            dispatch(startLoading());
            const result = await requestActionsTelcos(checkBoxItems, actions);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                let newTelcos;
                switch (actions) {
                    case 'delete':
                        newTelcos = telcos.filter((item) => !checkBoxItems.includes(item._id));
                        break;
                    case 'on':
                        newTelcos = telcos.map((item) => {
                            if (checkBoxItems.includes(item._id) && item.active) {
                                return { ...item, status: true };
                            } else {
                                return item;
                            }
                        });
                        break;
                    case 'off':
                        newTelcos = telcos.map((item) => {
                            if (checkBoxItems.includes(item._id) && item.active) {
                                return { ...item, status: false };
                            } else {
                                return item;
                            }
                        });
                        break;
                    default:
                        break;
                }
                if (newTelcos) {
                    setActions('');
                    setTelcos(newTelcos);
                    setCheckBoxItems([]);
                    setCheckBoxAll(false);
                    alertSuccess(result.message);
                }
            } else {
                alertError(result.error);
            }
        }
    };

    // Hàm thay đổi trạng thái sản phẩm
    const handleToggle = async (id, index, type) => {
        if (!id) {
            alertError('ID sản phẩm không tồn tại');
        } else {
            dispatch(startLoading());
            const result = await requestUpdateTelco({}, id, type);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                const clone = [...telcos];
                if (type === 'status') {
                    clone[index].status = !clone[index].status;
                } else {
                    clone[index].active = !clone[index].active;
                }
                setTelcos(clone);
            } else {
                alertError(result.error);
            }
        }
    };

    // Hàm tìm kiếm sản phẩm theo điều kiện
    const handleSearch = async () => {
        if (!searchValue) {
            alertError('Vui lòng nhập từ khóa cần tìm');
        } else {
            dispatch(startLoading());
            const result = await requestSearchTelcos(searchType, searchValue);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setTelcos(result.data);
            } else {
                alertError(result.error);
            }
        }
    };

    // Hàm xác nhận xóa sản phẩm
    const handleConfirm = async () => {
        if (!telco._id) {
            return alertError('Dữ liệu sản phẩm cần xóa không tồn tại');
        }

        dispatch(startLoading());
        const result = await requestDestroyTelco(telco._id);

        setShow(false);
        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            alertSuccess(result.message);
            const clone = [...telcos];
            clone.splice(index, 1);
            setTelcos(clone);
        } else {
            alertError(result.error);
        }
    };

    // Hảm hỏi xác nhận xóa sản phẩm
    const handleShowDestroy = async (telco, index) => {
        setShow(true);
        setTelco(telco);
        setIndex(index);
    };

    // Hàm chuyển sang trang chỉnh sửa
    const handleNavigate = (telco) => {
        if (!telco.active & !currentUser?.status) {
            return alertError('Thẻ này không thể chỉnh sửa');
        }

        navigate(`${softcard}/edit/${telco._id}`);
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Danh sách sản phẩm" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header>
                                <Row className="justify-content-end">
                                    <Col className="col-6 px-0" md={2}>
                                        <select
                                            name="type"
                                            className="form-control"
                                            value={searchType}
                                            onChange={(e) => setSearchType(e.target.value)}
                                        >
                                            <option value="code">Mã sản phẩm</option>
                                            <option value="name">Tên sản phẩm</option>
                                        </select>
                                    </Col>
                                    <Col className="col-6 px-0" md={2}>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Nhập vào đây"
                                                value={searchValue}
                                                onChange={(e) => setSearchValue(e.target.value)}
                                            />
                                            <div className="input-group-append">
                                                <Button variant="warning" title="Tìm kiếm" onClick={handleSearch}>
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input type="checkbox" checked={checkBoxAll} onChange={handleCheckBoxAll} />
                                                </th>
                                                <th>TT</th>
                                                <th>Tên sản phẩm</th>
                                                <th>Mã sản phẩm</th>
                                                <th>Giảm giá</th>
                                                <th>Ảnh</th>
                                                <th>Ảnh</th>
                                                <th>Trạng thái</th>
                                                <th>Kích hoạt</th>
                                                <th>Ngày tạo</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {telcos.length > 0 ? (
                                                telcos.map((telco, index) => (
                                                    <tr key={telco._id}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={checkBoxItems.includes(telco._id)}
                                                                onChange={() => handleCheckBoxItem(telco._id)}
                                                            />
                                                        </td>
                                                        <td>{telco.priority}</td>
                                                        <td style={{ width: 200 }}>{telco.title}</td>
                                                        <td>{telco.product_code}</td>
                                                        <td>{telco.discount}%</td>
                                                        <td>
                                                            <img
                                                                src={telco.image_url}
                                                                alt={telco.telco}
                                                                style={{ width: 120, height: 70 }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <img
                                                                src={telco.image_mobile}
                                                                alt={telco.telco}
                                                                style={{ width: 70, height: 70, borderRadius: 14 }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <div
                                                                className={`switch round ${telco.status ? 'on' : 'off'}`}
                                                                onClick={() => handleToggle(telco._id, index, 'status')}
                                                            >
                                                                <div className="toggle" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div
                                                                className={`switch round ${telco.active ? 'on' : 'off'}`}
                                                                onClick={() => handleToggle(telco._id, index, 'active')}
                                                            >
                                                                <div className="toggle" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span>{moment(telco.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                className="mr-2"
                                                                variant="info"
                                                                title="Sửa"
                                                                onClick={() => handleNavigate(telco)}
                                                            >
                                                                <FontAwesomeIcon icon={faPen} />
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                title="Xóa"
                                                                onClick={() => handleShowDestroy(telco, index)}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={11}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                            <Card.Footer>
                                <Row>
                                    <Col xl={3}>
                                        <div className="input-group">
                                            <select className="form-control" value={actions} onChange={(e) => setActions(e.target.value)}>
                                                <option value="">Vui lòng chọn hành động</option>
                                                <option value="on">Bật sản phẩm đã chọn</option>
                                                <option value="off">Tắt sản phẩm đã chọn</option>
                                                <option value="delete">Xóa sản phẩm đã chọn</option>
                                            </select>

                                            <div className="input-group-append">
                                                <Button variant="warning" onClick={handleActions} title="Hành động">
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                    <span>Thực hiện</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                    {pages > 1 && (
                                        <Col xl={9}>
                                            <div className="float-right mt-3 mt-md-0">
                                                <CusPagination pages={pages} page={page} setSearchParams={setSearchParams} />
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>

            {show && <Modals show={show} setShow={setShow} name={telco.title} onClick={handleConfirm} />}
        </div>
    );
}

export default Telco;

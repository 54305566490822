import { createSlice } from '@reduxjs/toolkit';

const statsReducer = createSlice({
    name: 'players',
    initialState: {
        players: null,
    },
    reducers: {
        setSocketPlayer: (state, { payload }) => {
            state.players = payload;
        },
    },
});

export const { setSocketPlayer } = statsReducer.actions;

export default statsReducer.reducer;

import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function ToastMessage({ app }) {
    const { type, message } = app;

    useEffect(() => {
        if (type === 'success') {
            toast.success(message);
        } else if (type === 'warning') {
            toast.warning(message);
        } else if (type === 'error') {
            toast.error(message);
        } else if (type === 'info') {
            toast.info(message);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app]);

    return (
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    );
}

export default ToastMessage;

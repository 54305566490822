import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faMagnifyingGlass, faTrashCan, faUsers } from '@fortawesome/free-solid-svg-icons';

import Modals from '~/components/Modals';
import PageTitle from '~/components/PageTitle';
import config, { convertCurrency } from '~/configs';
import { logoutSuccess } from '~/redux/reducer/auth';
import CusPagination from '~/components/CusPagination';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestDestroyUser, requestGetUsers, requestUpdateUser } from '~/services/user';

const { login } = config.routes;

function Users() {
    const [pages, setPages] = useState(1);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [show, setShow] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState(searchParams.get('account_id') || '');

    const page = searchParams.get('page');
    const status = searchParams.get('status');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { players } = useSelector((state) => state.players);

    useEffect(() => {
        document.title = 'Danh sách người dùng -  Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());

            let objectSearch = {};
            objectSearch.page = page || 1;

            if (status) {
                objectSearch.status = status;
            }
            if (searchValue) {
                objectSearch.account_id = searchValue;
            }

            const result = await requestGetUsers(objectSearch);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setUsers(result.data);
                setPages(result.pages);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, status]);

    // Socket người dùng mới
    useEffect(() => {
        if (!players) return;

        setUsers([players, ...users]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [players]);

    // Hàm thay đổi trạng thái người dùng
    const handleToggle = async (id, index) => {
        if (!id) {
            alertError('ID người dùng không tồn tại');
        } else {
            dispatch(startLoading());
            const result = await requestUpdateUser(id);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                const clone = [...users];
                clone[index].status = !clone[index].status;
                setUsers(clone);
            } else {
                alertError(result.error);
            }
        }
    };

    // Hàm tìm kiếm người dùng
    const handleSearch = async () => {
        if (!searchValue) {
            return alertError('Vui lòng nhập ID cần tìm');
        }

        dispatch(startLoading());
        let objectSearch = {};
        objectSearch.page = 1;
        objectSearch.account_id = searchValue;
        setSearchParams(objectSearch);

        const result = await requestGetUsers(objectSearch);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            setUsers(result.data);
            setPages(result.pages);
        } else {
            alertError(result.error);
        }
    };

    // Hàm xóa người dùng
    const handleDestroy = async () => {
        if (!user) {
            return alertError('Người dùng không tồn tại');
        }

        dispatch(startLoading());
        const result = await requestDestroyUser(user._id);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            const clone = users.filter((item) => item._id !== user._id);
            setUsers(clone);
            setShow(false);
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    const handleGetUser = async () => {
        if (status === 'true' || !status) {
            setSearchParams({ page: 1, status: false });
        } else {
            setSearchParams({ page: 1, status: true });
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Người dùng" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header>
                                <Row className="justify-content-end">
                                    <Col md={1} className="col-4 px-0">
                                        <Button variant="dark" onClick={handleGetUser}>
                                            <FontAwesomeIcon icon={faUsers} />
                                            <span>{status === 'false' ? 'Tất cả' : 'Bị khóa'}</span>
                                        </Button>
                                    </Col>
                                    <Col md={2} className="col-8 px-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Tìm kiếm bằng ID"
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                        />
                                    </Col>
                                    <div className="input-group-append mt-3 mt-md-0">
                                        <Button variant="warning" title="Tìm kiếm" onClick={handleSearch}>
                                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                                        </Button>
                                        <Button
                                            variant="success"
                                            className="ml-2 ml-md-0"
                                            title="Tải xuống"
                                            onClick={() => alertError('Chức năng đang được phát triển')}
                                        >
                                            <FontAwesomeIcon icon={faDownload} />
                                        </Button>
                                    </div>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped dataTable">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Nickname</th>
                                                <th>Quốc gia</th>
                                                <th>Tổng nạp</th>
                                                <th>Thực</th>
                                                <th>Nhận</th>
                                                <th>Trạng thái</th>
                                                <th>Ngày tạo</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.length > 0 ? (
                                                users.map((user, index) => (
                                                    <tr key={user._id}>
                                                        <td>{user.account_id}</td>
                                                        <td>{user.nickname}</td>
                                                        <td>{user.region}</td>
                                                        <td className="text-danger">{convertCurrency(user.declared_value)}</td>
                                                        <td className="text-success">{convertCurrency(user.value)}</td>
                                                        <td className="text-info">{convertCurrency(user.amount)}</td>
                                                        <td>
                                                            <div
                                                                className={`switch round ${user.status ? 'on' : 'off'}`}
                                                                onClick={() => handleToggle(user._id, index)}
                                                            >
                                                                <div className="toggle" />
                                                            </div>
                                                        </td>
                                                        <td>{moment(user.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                title="Xóa"
                                                                onClick={() => {
                                                                    setUser(user);
                                                                    setShow(true);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={10}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>

                            {pages > 1 && (
                                <Card.Footer>
                                    <Row>
                                        <Col xl={12}>
                                            <div className="float-right">
                                                <CusPagination
                                                    pages={pages}
                                                    page={page}
                                                    setSearchParams={setSearchParams}
                                                    params={searchValue && { account_id: searchValue }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>

            {show && <Modals show={show} setShow={setShow} name={user.nickname} onClick={handleDestroy} />}
        </div>
    );
}

export default Users;

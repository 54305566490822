import { useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PageTitle from '~/components/PageTitle';

function Notification() {
    useEffect(() => {
        document.title = 'Thông báo hệ thống - Quản trị website';
    }, []);

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Thông báo hệ thống" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <div className="table-responsive">
                                    <table className="table table-bordered dataTable">
                                        <thead>
                                            <tr>
                                                <th>Tiêu đề</th>
                                                <th>Nội dung</th>
                                                <th>Ngày tạo</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-line">
                                                <td>Hết hạn sử dụng mã nguồn</td>
                                                <td>Thời gian mã nguồn hết hạn 23:59 23-05-2024 ( Còn 29 Ngày )</td>
                                                <td>2023-12-24 20:47:43</td>
                                                <td>
                                                    <Button size="sm" className="mr-2" title="Đánh dấu đã đọc">
                                                        <FontAwesomeIcon icon={faEyeSlash} />
                                                    </Button>
                                                    <Button size="sm" variant="success">
                                                        Gia hạn
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Notification;

import request from '~/utils';

export const requestGetSettings = async () => {
    try {
        const res = await request.get('/settings', {
            params: {
                _v: Math.random(),
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUpdateSetting = async (data, type) => {
    try {
        const res = await request.put('/settings/update', data, {
            params: {
                type,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetConfigCharging = async () => {
    try {
        const res = await request.get('/settings/config-charging', {
            params: {
                _v: Math.random(),
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetPartnerDefault = async () => {
    try {
        const res = await request.get('/settings/partner-default', {
            params: {
                _v: Math.random(),
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUpdateConfigCharging = async (data) => {
    try {
        const res = await request.put('/settings/update-config', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetDataExports = async (type) => {
    try {
        const res = await request.get('/export-data', {
            params: {
                type,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestDestroyDatabase = async (type) => {
    try {
        const res = await request.delete('/database/destroy', {
            params: {
                type,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card, Col, Collapse, Row } from 'react-bootstrap';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faChevronRight, faHandHoldingDollar, faMinus, faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';

import './Home.css';
import { alertError } from '~/configs/alert';
import PageTitle from '~/components/PageTitle';
import { requestGetUsers } from '~/services/user';
import config, { convertCurrency } from '~/configs';
import { logoutSuccess } from '~/redux/reducer/auth';
import { requestGetRequests } from '~/services/request';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestGetStatChargings, requestGetTotalCharging } from '~/services/charging';

const { statistic, chargings: path, users: pathUser, login, request } = config.routes;

const color = ['primary', 'success', 'info'];
const iconFontAwesome = [faCreditCard, faMoneyBill1Wave, faHandHoldingDollar];

function Home() {
    const [collapseOne, setCollapseOne] = useState(true);
    const [collapseTow, setCollapseTow] = useState(true);
    const [collapseThree, setCollapseThree] = useState(true);

    const [users, setUsers] = useState(null);
    const [requests, setRequests] = useState(null);
    const [chargings, setChargings] = useState(null);
    const [totalChargings, setTotalChargings] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { charging } = useSelector((state) => state.stats);
    const { players } = useSelector((state) => state.players);

    // Socket thẻ mới
    useEffect(() => {
        if (!charging || !chargings) return;

        const data = totalChargings.map((charge) => {
            if (charge.title === 'Tổng thẻ nhận' || charge.title === 'Thẻ nhận hôm nay') {
                return {
                    ...charge,
                    value: charge.value + charging.declared_value,
                };
            }
            if (charge.title === 'Tổng thẻ đúng' || charge.title === 'Thẻ đúng hôm nay') {
                return {
                    ...charge,
                    value: charge.value + charging.value,
                };
            }
            if (charge.title === 'Tổng tiền nhận' || charge.title === 'Tiền nhận hôm nay') {
                return {
                    ...charge,
                    value: charge.value + charging.amount,
                };
            }
            return charge;
        });
        setTotalChargings(data);

        if (chargings?.data.length > 7) {
            chargings?.data.pop();
        }

        const newCharging = {
            _id: charging._id,
            telco: charging.telco,
            status: charging.status,
            serial: charging.serial,
            message: charging.message,
            declared_value: charging.declared_value,
            createdAt: charging.createdAt,
            code: charging.code,
        };

        setChargings({
            new: chargings?.new + 1,
            total: chargings?.total + 1,
            data: [newCharging, ...chargings?.data],
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [charging]);

    // Socket người dùng mới
    useEffect(() => {
        if (!players || !users) return;

        if (users?.data.length > 7) {
            users?.data.pop();
        }

        const newPlayer = {
            _id: players._id,
            nickname: players.nickname,
            account_id: players.account_id,
            region: players.region,
            createdAt: players.createdAt,
        };

        setUsers({
            new: users?.new + 1,
            total: users?.total + 1,
            data: [newPlayer, ...users?.data],
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [players]);

    useEffect(() => {
        document.title = 'Bảng quản trị - Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const users = await requestGetUsers({ type: 'tab' });
            const request = await requestGetRequests({ type: 'tab' });
            const totalCharging = await requestGetTotalCharging('tab');
            const chargings = await requestGetStatChargings({ type: 'tab' });

            dispatch(stopLoading());
            if (!users || !chargings || !totalCharging || !request) {
                dispatch(logoutSuccess());
                navigate(login);
                window.location.reload();
                return;
            }

            if (
                users.status === 401 ||
                users.status === 403 ||
                request.status === 401 ||
                request.status === 403 ||
                chargings.status === 401 ||
                chargings.status === 403 ||
                totalCharging.status === 401 ||
                totalCharging.status === 403
            ) {
                dispatch(logoutSuccess());
                return navigate(login);
            }

            if (totalCharging.status === 200) {
                const data = totalCharging.data.map((item, index) => ({
                    title: item.title,
                    value: item.value,
                    icon: iconFontAwesome[index % iconFontAwesome.length] || '',
                    color: color[index % color.length] || '',
                }));
                setTotalChargings(data);
            } else {
                alertError(totalCharging.error);
            }
            users.status === 200 ? setUsers(users.data) : alertError(users.error);
            request.status === 200 ? setRequests(request.data) : alertError(request.error);
            chargings.status === 200 ? setChargings(chargings.data) : alertError(chargings.error);
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="wrapper" id="home">
            <div className="header">
                <Row>
                    <PageTitle name="Bảng quản trị" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    {totalChargings.map((charging, index) => (
                        <Col xl={2} key={index}>
                            <div className="home-box">
                                <span className={`home-icon bg-${charging.color}`}>
                                    <FontAwesomeIcon icon={charging.icon} />
                                </span>
                                <div className="home-content">
                                    <span className="home-text">{charging.title}</span>
                                    <span className="home-number">{convertCurrency(charging.value)}</span>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>

                <Row>
                    {/* Tổng thẻ gửi */}
                    <Col xl={4}>
                        <Card className="mb-4">
                            <Card.Header>
                                <Card.Title>Tổng thẻ gửi</Card.Title>
                                <div>
                                    <Badge bg="success" className="mr-1">
                                        Mới {chargings?.new || 0}
                                    </Badge>
                                    <Badge bg="primary" className="mr-2">
                                        Tổng {chargings?.total || 0}
                                    </Badge>
                                    <button className="home-btn" onClick={() => setCollapseOne(!collapseOne)}>
                                        <FontAwesomeIcon icon={faMinus} />
                                    </button>
                                </div>
                            </Card.Header>
                            <Collapse in={collapseOne}>
                                <Card.Body>
                                    <ul className="transfer-list">
                                        {chargings?.data.map((charging, index) => (
                                            <li className="transfer-item" key={index}>
                                                <FontAwesomeIcon icon={faChevronRight} className="transfer-icon" />
                                                <span className="text-dark">
                                                    <strong>
                                                        {charging.telco?.product_code}: {charging.code}
                                                    </strong>
                                                    <strong> / {charging.serial}</strong>
                                                </span>
                                                <Badge
                                                    bg={
                                                        charging.status === 1
                                                            ? 'success'
                                                            : charging.status === 2
                                                            ? 'info'
                                                            : charging.status === 99 ||
                                                              charging.status === 101 ||
                                                              charging.status === 103 ||
                                                              charging.status === 104
                                                            ? 'warning'
                                                            : 'danger'
                                                    }
                                                    className="float-right"
                                                >
                                                    {convertCurrency(charging.declared_value)}
                                                </Badge>
                                                <br />
                                                <small className="text-muted">
                                                    {moment(charging.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                                                </small>
                                                <small className="text-dark float-right">({charging.message})</small>
                                            </li>
                                        ))}
                                    </ul>
                                </Card.Body>
                            </Collapse>
                            <Card.Footer className="text-center py-3">
                                <Link to={statistic + path} target="_blank">
                                    Xem tất cả
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>

                    {/* Người dùng */}
                    <Col xl={4}>
                        <Card className="mb-4">
                            <Card.Header>
                                <Card.Title>Người dùng</Card.Title>
                                <div>
                                    <Badge bg="success" className="mr-1">
                                        Mới {users?.new || 0}
                                    </Badge>
                                    <Badge bg="primary" className="mr-2">
                                        Tổng {users?.total || 0}
                                    </Badge>
                                    <button className="home-btn" onClick={() => setCollapseThree(!collapseThree)}>
                                        <FontAwesomeIcon icon={faMinus} />
                                    </button>
                                </div>
                            </Card.Header>
                            <Collapse in={collapseThree}>
                                <Card.Body>
                                    <ul className="transfer-list">
                                        {users?.data.map((user) => (
                                            <li className="transfer-item" key={user._id}>
                                                <FontAwesomeIcon icon={faChevronRight} className="transfer-icon" />
                                                <strong className="text-uppercase">
                                                    <Link to={`${pathUser}?account_id=${user.account_id}`} target="_blank">
                                                        {user.nickname}
                                                    </Link>
                                                </strong>
                                                <span> ({user.region})</span>
                                                <br />
                                                <small className="text-muted">{moment(user.createdAt).format('YYYY-MM-DD HH:mm:ss')}</small>
                                            </li>
                                        ))}
                                    </ul>
                                </Card.Body>
                            </Collapse>
                            <Card.Footer className="text-center py-3">
                                <Link to={pathUser} target="_blank">
                                    Xem tất cả
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>

                    {/* Lịch sử truy cập website */}
                    <Col xl={4}>
                        <Card className="mb-4">
                            <Card.Header>
                                <Card.Title>Lịch sử truy cập website</Card.Title>
                                <div>
                                    <Badge bg="success" className="mr-1">
                                        Mới {requests?.new}
                                    </Badge>
                                    <Badge bg="primary" className="mr-2">
                                        Tổng {requests?.total}
                                    </Badge>
                                    <button className="home-btn" onClick={() => setCollapseTow(!collapseTow)}>
                                        <FontAwesomeIcon icon={faMinus} />
                                    </button>
                                </div>
                            </Card.Header>
                            <Collapse in={collapseTow}>
                                <Card.Body>
                                    <ul className="transfer-list">
                                        {requests?.data.map((request, index) => (
                                            <li className="transfer-item" key={index}>
                                                <FontAwesomeIcon icon={faChevronRight} className="transfer-icon" />
                                                <span className="text-dark">
                                                    <strong> {request.url}</strong>
                                                </span>
                                                <Badge bg="primary" className="float-right">
                                                    {request.unique_id}
                                                </Badge>
                                                <br />
                                                <small className="text-muted">
                                                    {moment(request.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                                                </small>
                                                <Badge bg="info" className="float-right">
                                                    {request.device}
                                                </Badge>
                                            </li>
                                        ))}
                                    </ul>
                                </Card.Body>
                            </Collapse>
                            <Card.Footer className="text-center py-3">
                                <Link to={request} target="_blank">
                                    Xem tất cả
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Home;

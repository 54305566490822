import { createSlice } from '@reduxjs/toolkit';

const statsReducer = createSlice({
    name: 'stats',
    initialState: {
        charging: null,
    },
    reducers: {
        setSocketCharging: (state, { payload }) => {
            state.charging = payload;
        },
    },
});

export const { setSocketCharging } = statsReducer.actions;

export default statsReducer.reducer;

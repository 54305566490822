import config from '~/configs';
import Home from '~/layouts/Home';
import Destroy from '~/pages/Destroy';
import Setting from '~/pages/Setting';
import NotFound from '~/layouts/NotFound';
import Users from '~/pages/account/Users';
import Admin from '~/pages/account/Admin';
import Advertise from '~/pages/Advertise';
import Telco from '~/pages/softcard/Telco';
import Slider from '~/pages/module/Slider';
import Member from '~/pages/module/Member';
import Update from '~/pages/softcard/Update';
import Actions from '~/pages/history/Actions';
import Partners from '~/pages/module/Partners';
import Login from '~/layouts/components/Login';
import StatUser from '~/pages/statistic/Users';
import HistoryUser from '~/pages/history/User';
import StatDaily from '~/pages/statistic/Daily';
import Request from '~/pages/Advertise/Request';
import Notification from '~/pages/Notification';
import HistoryAdmin from '~/pages/history/Admin';
import Callback from '~/pages/statistic/Callback';
import StatCharging from '~/pages/statistic/Charging';
import HistoryCallBack from '~/pages/history/Callback';
import UpdateSlider from '~/pages/module/Slider/Update';
import UpdateMember from '~/pages/module/Member/Update';
import ForgotPassword from '~/components/ForgotPassword';
import UpdatePartner from '~/pages/module/Partners/Update';

const {
    home,
    users,
    tools,
    login,
    admin,
    member,
    forgot,
    history,
    sliders,
    actions,
    request,
    callback,
    partners,
    softcard,
    settings,
    statistic,
    chargings,
    dailystat,
    advertises,
    notifications,
} = config.routes;

const privateRoutes = [
    { path: home, component: Home },
    { path: users, component: Users },
    { path: admin, component: Admin },
    { path: member, component: Member },
    { path: tools, component: Destroy },
    { path: sliders, component: Slider },
    { path: softcard, component: Telco },
    { path: request, component: Request },
    { path: settings, component: Setting },
    { path: partners, component: Partners },
    { path: advertises, component: Advertise },
    { path: login, component: Login, layout: null },
    { path: history + actions, component: Actions },
    { path: '*', component: NotFound, layout: null },
    { path: notifications, component: Notification },
    { path: statistic + users, component: StatUser },
    { path: history + users, component: HistoryUser },
    { path: history + admin, component: HistoryAdmin },
    { path: softcard + '/edit/:id', component: Update },
    { path: statistic + dailystat, component: StatDaily },
    { path: partners + '/edit', component: UpdatePartner },
    { path: member + '/edit/:id', component: UpdateMember },
    { path: sliders + '/edit/:id', component: UpdateSlider },
    { path: statistic + chargings, component: StatCharging },
    { path: history + callback, component: HistoryCallBack },
    { path: forgot, component: ForgotPassword, layout: null },
    { path: statistic + chargings + callback, component: Callback },
];

export { privateRoutes };

import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';

import config from '~/configs';
import PageTitle from '~/components/PageTitle';
import NoAvatar from '~/assets/image/no-avt.jpg';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestChangePassword, requestUpdateAdmin, requestGetAdmin } from '~/services/admin';

const { login } = config.routes;

function Admin() {
    const [id, setID] = useState('');
    const [users, setUsers] = useState([]);
    const [show, setShow] = useState(false);
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Danh sách quản trị -  Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestGetAdmin();

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setUsers(result.data);
            } else {
                alertError(result.error);
            }
        };
        fetch();
    }, [dispatch, navigate]);

    const handleToggle = async (id, index, type) => {
        if (!id) {
            alertError('ID người dùng không tồn tại');
        } else {
            dispatch(startLoading());
            const result = await requestUpdateAdmin(type, id);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                const clone = [...users];
                if (type === 'status') {
                    clone[index].status = !clone[index].status;
                } else {
                    clone[index].admin = !clone[index].admin;
                }
                setUsers(clone);
            } else {
                alertError(result.error);
            }
        }
    };

    // Hàm sử lý sử mật khẩu
    const handleChangePass = async () => {
        if (!password || !newPassword || !rePassword) {
            return alertError('Vui lòng nhập đầy đủ thông tin');
        }
        if (password.length < 8) {
            return alertError('Mật khẩu hiện tại không chính xác');
        }
        if (newPassword.length < 8) {
            return alertError('Mật khẩu mới không đúng định dạng');
        }
        if (newPassword !== rePassword) {
            return alertError('Mật khẩu mới nhập lại không trùng khớp');
        }

        dispatch(startLoading());
        const data = {
            current_password: password,
            new_password: newPassword,
        };

        const result = await requestChangePassword(data, id);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            alertSuccess(result.message);
            setShow(false);
            setPassword('');
            setRePassword('');
            setNewPassword('');
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Quản trị viên" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped dataTable">
                                        <thead>
                                            <tr>
                                                <th>Tên</th>
                                                <th>Email</th>
                                                <th>Ảnh</th>
                                                <th>Quản trị</th>
                                                <th>Trạng thái</th>
                                                <th>Ngày tạo/cập nhật</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.length > 0 ? (
                                                users.map((user, index) => (
                                                    <tr key={user._id}>
                                                        <td>{user.full_name}</td>
                                                        <td>{user.email}</td>
                                                        <td>
                                                            <img
                                                                src={user.avatar_url || NoAvatar}
                                                                alt={user.full_name}
                                                                style={{
                                                                    width: 60,
                                                                    height: 60,
                                                                    borderRadius: '50%',
                                                                    objectFit: 'cover',
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <div
                                                                className={`switch round ${user.admin ? 'on' : 'off'}`}
                                                                onClick={() => handleToggle(user._id, index, 'admin')}
                                                            >
                                                                <div className="toggle" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div
                                                                className={`switch round ${user.status ? 'on' : 'off'}`}
                                                                onClick={() => handleToggle(user._id, index, 'status')}
                                                            >
                                                                <div className="toggle" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {moment(user.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                                                            <br />
                                                            {moment(user.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                                                        </td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                variant="success"
                                                                title="Đổi mật khẩu"
                                                                onClick={() => {
                                                                    setShow(true);
                                                                    setID(user._id);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faUnlock} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={10}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
                <Modal.Header className="justify-content-center">
                    <Modal.Title style={{ fontSize: '1.8rem' }}>Đổi mật khẩu</Modal.Title>
                </Modal.Header>
                <Modal.Body className="my-4">
                    <Form.Group className="mb-3">
                        <Form.Label>Nhập mật khẩu hiện tại</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Nhập mật khẩu hiện tại"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Nhập mật khẩu mới</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Nhập mật khẩu mới"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Nhập lại mật khẩu mới</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Nhập lại mật khẩu mới"
                            value={rePassword}
                            onChange={(e) => setRePassword(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="secondary" onClick={() => setShow(false)}>
                        Đóng
                    </Button>
                    <Button size="sm" variant="success" onClick={handleChangePass}>
                        Đổi mật khẩu
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Admin;

import request from '~/utils';

export const requestGetHistories = async (type, page) => {
    try {
        const res = await request.get('/histories', {
            params: {
                type,
                page,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';

import config from '~/configs';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { requestCreateAdvertise } from '~/services/advertise';
import { startLoading, stopLoading } from '~/redux/reducer/module';

const { login } = config.routes;

function ModalKOL({ show, setShow, advertises, setAdvertises }) {
    const [queryId, setQueryId] = useState('');
    const [nickName, setNickName] = useState('');
    const [uniqueId, setUniqueId] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCreateSEO = async () => {
        if (!nickName || !queryId || !uniqueId) {
            return alertError('Vui lòng nhập đầy đủ thông tin');
        }

        const data = {
            nickname: nickName,
            query_id: queryId,
            unique_id: uniqueId,
        };

        dispatch(startLoading());
        const result = await requestCreateAdvertise(data);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            setShow(false);
            alertSuccess(result.message);
            setAdvertises([result.data, ...advertises]);
        } else {
            alertError(result.error);
        }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
            <Modal.Header className="justify-content-center">
                <Modal.Title style={{ fontSize: '1.8rem' }}>Thêm SEO</Modal.Title>
            </Modal.Header>
            <Modal.Body className="my-4">
                <Form.Group className="mb-3">
                    <Form.Label>Nhập tên</Form.Label>
                    <Form.Control placeholder="Nhập tên cần thêm" value={nickName} onChange={(e) => setNickName(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Nhập id</Form.Label>
                    <Form.Control placeholder="Nhập id cần thêm" value={uniqueId} onChange={(e) => setUniqueId(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Nhập mã seo</Form.Label>
                    <Form.Control placeholder="Nhập mã seo" value={queryId} onChange={(e) => setQueryId(e.target.value)} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={() => setShow(false)}>
                    Đóng
                </Button>
                <Button size="sm" variant="success" onClick={handleCreateSEO}>
                    Thêm mới
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalKOL;

import request from '~/utils';

export const requestGetTelcos = async (page = null, id = null) => {
    try {
        const res = await request.get('/telcos', {
            params: {
                page,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUpdateTelco = async (data, id, type = 'tab') => {
    try {
        const res = await request.put('/telcos/update', data, {
            params: {
                type,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestDestroyTelco = async (id) => {
    try {
        const res = await request.delete('/telcos/destroy', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestActionsTelcos = async (data, type) => {
    try {
        const res = await request.post('/telcos/actions', data, {
            params: {
                type,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestSearchTelcos = async (type, keyword) => {
    try {
        const res = await request.post(
            '/telcos/search',
            {},
            {
                params: {
                    type,
                    keyword,
                },
            },
        );

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const alertSuccess = (message) => {
    MySwal.fire('THÔNG BÁO', message, 'success');
};

export const alertError = (error) => {
    MySwal.fire('THÔNG BÁO', error || 'Lỗi hệ thống vui lòng thử lại', 'error');
};

export const alertSelected = (title, text) => {
    return MySwal.fire({
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
    });
};

import request from '~/utils';

export const requestGetMembers = async (id) => {
    try {
        const res = await request.get('/members', {
            params: {
                _v: !id ? Math.random() : null,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUpdateMember = async (data, id, type = null) => {
    try {
        const res = await request.put('/members/update', data, {
            params: {
                type,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestDestroyMember = async (id) => {
    try {
        const res = await request.delete('/members/destroy', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

import request from '~/utils';
import { loginSuccess } from '~/redux/reducer/auth';

export const requestGetCurrentUser = async () => {
    try {
        const res = await request.get('/auth/current-user', {
            params: {
                _v: Math.random(),
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestLoginUser = async (user, dispatch) => {
    try {
        const res = await request.post('/auth/login', user);

        const { data, ...other } = res.data;
        dispatch(loginSuccess(data));

        return { ...other };
    } catch (error) {
        return error.response?.data;
    }
};

export const requestLogoutUser = async () => {
    try {
        const res = await request.post(
            '/auth/logout',
            {},
            {
                params: {
                    _v: Math.random(),
                },
            },
        );

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestForgotPassword = async (data) => {
    try {
        const res = await request.post('/auth/forgot-password', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestVerifyEmail = async (data) => {
    try {
        const res = await request.post('/auth/verify-email', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons';

import config from '~/configs';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import { requestUploadImage } from '~/services/upload';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestGetSliders, requestUpdateSliders } from '~/services/slider';

const { sliders, login } = config.routes;

function Update() {
    const [title, setTitle] = useState('');
    const [linkUrl, setLinkUrl] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    const inputRef = useRef();
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Cập nhật trình diễn ảnh - Quản trị website';

        if (id) {
            const fetch = async () => {
                dispatch(startLoading());
                const result = await requestGetSliders(null, id);

                dispatch(stopLoading());
                if (result.status === 401 || result.status === 403) {
                    dispatch(logoutSuccess());
                    navigate(login);
                } else if (result.status === 200) {
                    const { title, link_url, image_url } = result.data;

                    setTitle(title);
                    setLinkUrl(link_url);
                    setImageUrl(image_url);
                } else {
                    alertError(result.error);
                }
            };
            fetch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleUpdate = async () => {
        if (!title || !linkUrl || !imageUrl) {
            return alertError('Vui lòng điền đủ thông tin');
        }

        dispatch(startLoading());
        const data = {
            title,
            link_url: linkUrl,
            image_url: imageUrl,
        };

        const result = await requestUpdateSliders(data, id);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    const handleUpload = async (e) => {
        dispatch(startLoading());

        const formData = new FormData();
        formData.append('image', e.target.files[0]);

        const result = await requestUploadImage(formData);

        dispatch(stopLoading());
        if (result.status === 200) {
            setImageUrl(result.data);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Sửa trình diễn ảnh" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Sửa trình diễn ảnh</Card.Title>

                                <Link to={sliders}>
                                    <Button variant="warning">
                                        <FontAwesomeIcon icon={faHouseChimney} />
                                        <span>Trang chính</span>
                                    </Button>
                                </Link>
                            </Card.Header>
                            <Card.Body>
                                <Col xl={6}>
                                    <div className="form-group mt-3">
                                        <label>Tiêu đề:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Đường dẫn:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={linkUrl}
                                            onChange={(e) => setLinkUrl(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="d-block">Ảnh:</label>
                                        <Button variant="info" className="mr-5" onClick={() => inputRef.current.click()}>
                                            Chọn ảnh
                                        </Button>
                                        <input type="file" ref={inputRef} onChange={(e) => handleUpload(e)} hidden />
                                        <img
                                            className="mt-2"
                                            src={imageUrl}
                                            alt={title}
                                            style={{
                                                border: '1px solid #ececec',
                                                maxWidth: '80%',
                                                height: 80,
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Card.Body>
                            <Card.Footer>
                                <Button onClick={handleUpdate}>{id ? 'Cập nhật' : 'Thêm mới'}</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Update;

import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTrashCan, faPen } from '@fortawesome/free-solid-svg-icons';

import config from '~/configs';
import Modals from '~/components/Modals';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestActionSliders, requestDestroySlider, requestGetSliders, requestUpdateSliders } from '~/services/slider';

const { sliders: path, login } = config.routes;

function Slider() {
    const [sliders, setSliders] = useState([]);
    const [actions, setActions] = useState('');
    const [checkBoxAll, setCheckBoxAll] = useState(false);
    const [checkBoxItems, setCheckBoxItems] = useState([]);

    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(null);
    const [slider, setSlider] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Danh sách trình diễn ảnh - Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestGetSliders();

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setSliders(result.data);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Hàm xử lý khi checkbox all
    const handleCheckBoxAll = (event) => {
        // Chỉ được checkbox all khi có sản phẩm
        if (sliders.length === 0) {
            return alertError('Không có sản phẩm nào');
        }

        const { checked } = event.target;
        setCheckBoxAll(checked);

        if (checked) {
            const allItemIds = sliders.map((slider) => slider._id);
            setCheckBoxItems(allItemIds);
        } else {
            setCheckBoxItems([]);
        }
    };

    // Hàm xử lý khi checkbox riêng lẻ
    const handleCheckBoxItem = (itemId) => {
        if (checkBoxItems.includes(itemId)) {
            // Nếu đã checkbox thì bỏ checkbox và set checkbox all = false
            setCheckBoxItems(checkBoxItems.filter((id) => id !== itemId));
            setCheckBoxAll(false);
        } else {
            // Nếu chưa checkbox thì trả lại các id đã checkbox và thêm id mới checkbox
            setCheckBoxItems([...checkBoxItems, itemId]);

            // Do checkBoxItems vừa được set lại nên telcos phải - 1 mới bằng được độ dài mảng
            if (checkBoxItems.length === sliders.length - 1) {
                setCheckBoxAll(true);
            }
        }
    };

    // Hàm sử lý hành động checkbox
    const handleActions = async () => {
        if (!actions) {
            alertError('Vui lòng chọn hành động');
        } else if (checkBoxItems.length === 0) {
            alertError('Vui lòng chọn sản phẩm');
        } else {
            dispatch(startLoading());
            const result = await requestActionSliders(checkBoxItems, actions);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                let newSliders;
                switch (actions) {
                    case 'delete':
                        newSliders = sliders.filter((item) => !checkBoxItems.includes(item._id));
                        break;
                    case 'on':
                        newSliders = sliders.map((item) => {
                            if (checkBoxItems.includes(item._id)) {
                                return { ...item, status: true };
                            } else {
                                return item;
                            }
                        });
                        break;
                    case 'off':
                        newSliders = sliders.map((item) => {
                            if (checkBoxItems.includes(item._id)) {
                                return { ...item, status: false };
                            } else {
                                return item;
                            }
                        });
                        break;
                    default:
                        break;
                }
                if (newSliders) {
                    setActions('');
                    setSliders(newSliders);
                    setCheckBoxItems([]);
                    setCheckBoxAll(false);
                    alertSuccess(result.message);
                }
            } else {
                alertError(result.error);
            }
        }
    };

    // Hàm thay đổi trạng thái sản phẩm
    const handleToggle = async (id, index) => {
        if (!id) {
            alertError('ID không tồn tại');
        } else {
            dispatch(startLoading());
            const result = await requestUpdateSliders({}, id, 'status');

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                const clone = [...sliders];
                clone[index].status = !clone[index].status;
                setSliders(clone);
            } else {
                alertError(result.error);
            }
        }
    };

    // Hàm hiển thị xác nhận xóa sản phẩm
    const handleDelete = async (slider, index) => {
        setShow(true);
        setIndex(index);
        setSlider(slider);
    };

    // Hàm xác nhận xóa sản phẩm
    const handleConfirm = async () => {
        if (!slider._id) {
            alertError('ID sản phẩm không tồn tại');
        } else {
            dispatch(startLoading());
            const result = await requestDestroySlider(slider._id);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                const clone = [...sliders];
                clone.splice(index, 1);
                setSliders(clone);
                setShow(false);
                alertSuccess(result.message);
            } else {
                alertError(result.error);
            }
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Trình diễn ảnh" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input type="checkbox" checked={checkBoxAll} onChange={handleCheckBoxAll} />
                                                </th>
                                                <th>Tên</th>
                                                <th>Hình ảnh</th>
                                                <th>Trạng thái</th>
                                                <th>Ngày tạo</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sliders.length > 0 ? (
                                                sliders.map((slider, index) => (
                                                    <tr key={slider._id}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={checkBoxItems.includes(slider._id)}
                                                                onChange={() => handleCheckBoxItem(slider._id)}
                                                            />
                                                        </td>
                                                        <td>{slider.title}</td>
                                                        <td>
                                                            <img
                                                                src={slider.image_url}
                                                                alt={slider.title}
                                                                style={{
                                                                    maxHeight: 80,
                                                                    maxWidth: 800,
                                                                    border: '1px solid #99999999',
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <div
                                                                className={`switch round ${slider.status ? 'on' : 'off'}`}
                                                                onClick={() => handleToggle(slider._id, index)}
                                                            >
                                                                <div className="toggle" />
                                                            </div>
                                                        </td>
                                                        <td>{moment(slider.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                        <td>
                                                            <Link to={`${path}/edit/${slider._id}`} className="mr-2">
                                                                <Button size="sm" variant="info" title="Sửa">
                                                                    <FontAwesomeIcon icon={faPen} />
                                                                </Button>
                                                            </Link>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                title="Xóa"
                                                                onClick={() => handleDelete(slider, index)}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={7}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                            <Card.Footer>
                                <Row>
                                    <Col xl={3} className="p-0">
                                        <div className="input-group">
                                            <select className="form-control" value={actions} onChange={(e) => setActions(e.target.value)}>
                                                <option value="">Vui lòng chọn hành động</option>
                                                <option value="on">Bật sản phẩm đã chọn</option>
                                                <option value="off">Tắt sản phẩm đã chọn</option>
                                                <option value="delete">Xóa sản phẩm đã chọn</option>
                                            </select>
                                            <div className="input-group-append">
                                                <Button variant="warning" title="Hành động" onClick={handleActions}>
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                    <span>Thực hiện</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>

            {show && <Modals show={show} setShow={setShow} name={slider.title} onClick={handleConfirm} />}
        </div>
    );
}

export default Slider;

import request from '~/utils';

export const requestGetRequests = async ({ page, type }) => {
    try {
        const res = await request.get('/requests', {
            params: {
                type,
                page,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestActionRequest = async (type, id) => {
    try {
        const res = await request.post(
            '/requests/actions',
            {},
            {
                params: {
                    type,
                    id,
                },
            },
        );

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

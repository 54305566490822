import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons';

import config from '~/configs';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestGetConfigCharging, requestGetPartnerDefault, requestUpdateConfigCharging } from '~/services/settings';

const { statistic, chargings, login } = config.routes;

function Callback() {
    const [partnerId, setPartnerId] = useState('');
    const [partnerKey, setPartnerKey] = useState('');
    const [partnerName, setPartnerName] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Cấu hình callback - Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestGetConfigCharging();

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                setPartnerName(result.data.partner_name);
                setPartnerId(result.data.partner_id);
                setPartnerKey(result.data.partner_key);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGetPartnerDefault = async () => {
        dispatch(startLoading());
        const result = await requestGetPartnerDefault();

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            setPartnerName(result.data.partner_name);
            setPartnerId(result.data.partner_id);
            setPartnerKey(result.data.partner_key);
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    const handleUpdateConfigCallback = async () => {
        if (!partnerName || !partnerId || !partnerKey) {
            return alertError('Vui lòng điền đầy đủ thông tin');
        }

        dispatch(startLoading());
        const data = {
            partner_name: partnerName,
            partner_id: partnerId,
            partner_key: partnerKey,
        };

        const result = await requestUpdateConfigCharging(data);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Cấu hình callback" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Cấu hình</Card.Title>
                                <Link to={statistic + chargings}>
                                    <Button variant="warning">
                                        <FontAwesomeIcon icon={faHouseChimney} />
                                        <span>Trang chính</span>
                                    </Button>
                                </Link>
                            </Card.Header>
                            <Card.Body>
                                <Col xl={6} className="p-0 mt-3">
                                    <div className="form-group col-md-12">
                                        <label>Partner:</label>
                                        <select
                                            className="form-control"
                                            value={partnerName}
                                            onChange={(e) => setPartnerName(e.target.value)}
                                        >
                                            <option value="">Chọn đối tác nhận callback</option>
                                            <option value="Doithe">Doithe</option>
                                            <option value="Pay1s">Pay1s</option>
                                            <option value="Azcard">Azcard</option>
                                            <option value="Webthe">Webthe</option>
                                            <option value="Trumthe">Trumthe</option>
                                            <option value="Thegiare">Thegiare</option>
                                            <option value="Thesieure">Thesieure</option>
                                            <option value="Thenhanh">Thenhanh</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Partner ID:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Nhập ID đối tác"
                                            value={partnerId}
                                            onChange={(e) => setPartnerId(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Partner Key:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Nhập Key đối tác"
                                            value={partnerKey}
                                            onChange={(e) => setPartnerKey(e.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Card.Body>
                            <Card.Footer>
                                <Button
                                    variant="success"
                                    className="mr-4"
                                    title="Chọn đối tác đang hoạt động làm callback"
                                    onClick={handleGetPartnerDefault}
                                >
                                    Mặc định
                                </Button>
                                <Button onClick={handleUpdateConfigCallback}>Cập nhật</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Callback;
